import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

const iconAspectRatio = 1.9121;

const Container = styled.div`
    display: inline-block;
    height: 64px;
    width: ${64 * iconAspectRatio}px;

    @media screen and (max-width: 768px) {
        height: 48px;
        width: ${48 * iconAspectRatio}px;
    }
`;

const BrandIcon = () => {
    return (
        <Link to="/" style={{ lineHeight: 0 }}>
            <StaticImage
                as={Container}
                src="../../static/images/haft.png"
                alt="The Haft logo"
            />
        </Link>
    );
};

export default BrandIcon;
