import React from "react";
import Header from "./Header";

const Layout = ({ children, hasMaxWidth = true, mainStyle }) => {
    const mainHasMaxWidth = hasMaxWidth;
    const mainStyles = mainHasMaxWidth
        ? {
              ...mainStyle,
              maxWidth: "1000px",
              margin: "0 auto",
          }
        : {
              ...mainStyle,
              margin: "0 auto",
          };

    return (
        <>
            <Header />
            <main style={mainStyles}>{children}</main>
        </>
    );
};

export default Layout;
